// src/plugins/vuetify.js

import Vue from 'vue'
import Vuetify from 'vuetify'
import 'vuetify/dist/vuetify.min.css'
import '@mdi/font/css/materialdesignicons.css'
import ICONS from '@/icons'

Vue.use(Vuetify)

import i18n from '@/lang' // Internationalization

const opts = {
  icons: {
    iconfont: 'mdi', // 默认值 - 仅用于展示目的
    values: ICONS
  },
  lang: {
    t: (key, ...params) => i18n.t(key, params)
  },
  theme: {
    theme: {
      disable: true
    }
  }
}

export default new Vuetify(opts)
