<template>
  <v-row no-gutters style="margin-left:60px;margin-right:60px;" class="fill-height">
    <v-col cols="5" class="d-flex justify-start align-center">
      <v-img
        :src="require(`@/assets/footer/logo-black@3x.png`)"
        max-width="86px"
        max-height="28px"
        class="d-inline-block"
      />
      <span class="kooola-text">
        |{{ $t('footer.kooola_text') }}
      </span>
    </v-col>
    <v-spacer />
    <v-col cols="7" class="d-flex justify-end align-center">
      <a
        v-for="item in ['twitter', 'medium', 'telegram', 'ins']"
        :key="item"
        :href="hrefs[item]"
        target="_blank"
      >
        <v-img
          :src="require('@/assets/icon/'+ item +'@3x.png')"
          max-width="50"
          max-height="50"
          class="mx-1"
        />
      </a>
      <div class="email-divider" />
      <v-img
        :src="require(`@/assets/icon/email-symbol@3x.png`)"
        max-width="16px"
        max-height="13px"
        class="d-inline-block"
        style="margin-right:10px"
      />
      <span class="email-text d-inline-block">hello@kooola.com</span>
    </v-col>
  </v-row>
</template>
<script>
export default {
  name: 'MyFooter',
  data() {
    return {
      hrefs: {
        twitter: 'https://twitter.com/RealyOfficial',
        medium: 'https://medium.com/@realy',
        telegram: 'https://t.me/realyofficial',
        ins: 'https://instagram.com/realy.pro?utm_medium=copy_link',
        // weibo: 'https://weibo.com/u/7610259688'
      }
    }
  }
}
</script>

<style scoped>

.email-text {
  height: 26px;
  font-size: 14px;
  font-weight: 400;
  color: #000000;
  line-height: 26px;
  opacity: 0.5;
}

.kooola-text {
  height: 26px;
  font-size: 14px;
  font-weight: 400;
  color: #000000;
  line-height: 26px;
  opacity: 0.6;
  text-align: center;
  margin-left: 15px;
}

.email-divider {
  width: 1px;
  height: 20px;
  opacity: 0.2;
  border: 1px solid #000000;
  margin-right: 23px;
  margin-left: 12px;
}
</style>
