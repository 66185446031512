import axios from "axios";
import store from "@/store";
import Vue from "vue";
import i18n from "../lang";
import GlobalVue from "../common/global/GlobalVue";
// create an axios instance
const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API, // api 的 base_url
  timeout: 5000, // request timeout
});

// request interceptor
service.interceptors.request.use(
  (config) => {
    // Do something before request is sent
    if (store.getters.token) {
      // 让每个请求携带token-- ['X-Token']为自定义key 请根据实际情况自行修改
      config.headers["Authorization"] = store.getters.token;
    }
    if (store.getters.language === "en") {
      config.headers["X-Exchange-Info"] =
        "language=en&source=web&version=1.0&appname=kooolaInt&callSource=dropMerchant";
    } else {
      config.headers["X-Exchange-Info"] =
        "language=zh-Hans&source=web&version=1.0&appname=kooolaInt&callSource=dropMerchant";
    }
    /**
     * 存储到本地
     */
    localStorage.setItem("X-Exchange-Info", config.headers["X-Exchange-Info"]);
    return config;
  },
  (error) => {
    // Do something with request error
    console.log(error); // for debug
    Promise.reject(error);
  }
);

// response interceptor
service.interceptors.response.use(
  // response => response,
  /**
   * 下面的注释为通过在response里，自定义code来标示请求状态
   * 当code返回如下情况则说明权限有问题，登出并返回到登录页
   * 如想通过 xmlhttprequest 来状态码标识 逻辑可写在下面error中
   * 以下代码均为样例，请结合自生需求加以修改，若不需要，则可删除
   */
  (response) => {
    const res = response.data;
    if (res.code !== 0) {
      //白名单购买
      if (res.code == "OIS169") {
        const vue = GlobalVue.vueInstance;
        vue.$dialog
          .confirm({
            text: res.msg,
            showClose: false,
            actions: {
              false: "",
              true: vue.$t("alert.details"),
            },
          })
          .then((result) => {
            if (result) {
              vue.$router.history.go(-1);
            }
          });
        return Promise.reject(res);
      }
      // 26 token过期，刷新 5003 重新登陆
      else if (res.code === 5003) {
        Vue.notify({
          text: i18n.t("login.token_invalid"),
          type: "error",
        });
        store.dispatch('FedLogOut')
        store.dispatch("showLoginDlg", true);
      } else if (res.code === 2 || res.code === 6) {
        store
          .dispatch("RefreshToken")
          .then((resp) => console.log(resp))
          .catch((err) => {
            Vue.notify({
              text: i18n.t("login.token_invalid"),
              type: "error",
            });
            store.dispatch("showLoginDlg", true);
            console.log(err);
          });
      } else if (
        res.code === "SAS070" ||
        res.code === 5007 ||
        res.code === 5102 ||
        res.code === "OIS133"
      ) {
        return response.data;
      } else {
        Vue.notify({
          text: res.msg,
          type: "error",
        });
        return Promise.reject(res);
      }
    } else {
      return response.data;
    }
  },
  (error) => {
    console.log("err" + error); // for debug
    return Promise.reject(error);
  }
);

export default service;
