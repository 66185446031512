<script>
import style from "./LoginOwner.module.scss";
import title from "../../assets/login/title.png";
import icon from "../../assets/login/icon.png";
import reset from "../../assets/login/reset.png";
import close from "../../assets/login/close.png";
import { LoginOwnerController } from "./controller";
import { LoginOwnerLocalLang } from "./enum";
import { LoginControllerSingle } from "../../controller/LoginControllerSingle";
const singleController = new LoginControllerSingle.CreateInstance();
const InputRef = "inputRef";
const controller = new LoginOwnerController();
export default {
  data() {
    return {
      /*
       * 输入的input的value的值
       */
      ownerValue: "",
      /*
       * 弹窗的开关
       */
      visible: false,
      /**
       * 校验报错提示信息
       */
      errorValue: "",
    };
  },
  watch: {
    /**输入的值发生变化，重置报错提示信息 */
    ownerValue(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.errorValue = "";
      }
    },
    /**
     * 这边从store观察变量全局
     * 理解成全局开关
     */
    "$store.state.user.showLoginOwner"(newValue, oldValue) {
      console.log(newValue, oldValue);
      //值发生变化了
      if (newValue !== oldValue) {
        //开关弹窗
        this.visible = newValue;
        this.clear();
      }
    },
  },
  methods: {
    clear() {
      this.ownerValue = "";
    },
    /**
     * 初始化
     */
    initial() {
      if (this.$refs[InputRef]) {
        this.$refs[InputRef].focus();
      }
    },
    /**
     * 关闭弹窗
     */
    closeDialog() {
      singleController.toggleRegisterOwnerIdDialog({ visible: false });
    },
    /**
     * 重置value值
     */
    reset() {
      this.ownerValue = "";
    },
    /**
     * 提交表单
     * 成功关闭窗体
     * 失败返回错误信息
     */
    submit() {
      controller.register({
        ownerId: this.$data.ownerValue,
        errorCallback: ({ error }) => {
          this.errorValue = error;
        },
        succesCallback: () => {
          this.closeDialog();
        },
      });
    },
    /**
     * 登出关闭弹窗跳转回首页
     */
    logout() {
      controller.logout({
        logoutCallback: () => {
          this.closeDialog();
          this.$router.replace("/home");
        },
      });
    },
  },
  mounted() {
    this.initial();
  },
  render() {
    return (
      <v-dialog v-model={this.$data.visible} width="610" persistent>
        <img
          class={style.closeButton}
          src={close}
          on-click={() => this.logout()}
        />
        <div class={style.main}>
          <img class={style.title} src={title} />
          <div class={style.subTitleFlex}>
            <img src={icon} />
            <span>{this.$t(LoginOwnerLocalLang.SubTitle)}</span>
          </div>
          <div class={style.inputContainer}>
            <input
              placeholder="KOOOLA ID"
              v-model={this.$data.ownerValue}
              ref={InputRef}
            />
            {this.$data.ownerValue && (
              <img
                src={reset}
                class={style.reset}
                on-click={() => this.reset()}
              />
            )}
          </div>
          <div class={style.info}>{this.$t(LoginOwnerLocalLang.Prompt)}</div>
          <div class={style.error}>{this.$data.errorValue}</div>
          <div class={style.btnContainer}>
            <v-btn
              depressed
              color="primary"
              class={
                this.$data.ownerValue != ""
                  ? style.setUpEnable
                  : style.setUpDisable
              }
              on-click={() => this.submit()}
            >
              {this.$t(LoginOwnerLocalLang.Button)}
            </v-btn>
          </div>
        </div>
      </v-dialog>
    );
  },
};
</script>
