export default {
  header: {
    Home: "Home",
    Drop: "Drops",
    EN: "EN",
    CN: "CN",
    Foundation: "Foundation",
    NFT: "NFT",
    wallet: "Wallet",
  },
  footer: {
    kooola_text: "Powered by REALY",
  },
  home: {
    app_download: "APP DownLoad",
    baas_login: "Bass Login",
  },
  foundation: {
    litepaper: "Litepaper",
  },
  login: {
    title: "Welcome to KOOOLA!",
    notice_phone:
      "Account will be created automatically after verification of unregistered mobile number.",
    notice_email:
      "Account will be created automatically after verification of unregistered email.",
    phone: "phone",
    email: "email",
    account_title: "Login with password",
    input_phone: "Enter your mobile number",
    input_verify_code: "Enter the verification code",
    input_account: "Enter your ID/mobile number/email",
    input_passwd: "Enter your password",
    get_verify_code: "Send code",
    login_btn: "Log In",
    switch_account_login: "Password",
    switch_verifycode_login: "Verifcation",
    annous_1: "Register and log in to agree to the ",
    annous_2: "Privacy Ploicy",
    input_email: "Enter your email",
    relogin: "Login try again",
    email_invalid: "Invalid email address",
    verify_code_invalid: "Enter the verification code",
    verify_code_need: "Please get verification code",
    phone_invalid: "Invalid phone number",
    login_ok: "Login successfully",
    send_verify_ok_phone: "The verification code has been sent to your phone",
    send_verify_ok_email: "The verification code has been sent to your email",
    logout_ok: "Logout successfully",
    token_invalid: "Invalid Login, Please login retry",
  },
  $vuetify: {
    noDataText: "",
    pagination: {
      ariaLabel: {
        previous: "pre",
        next: "next",
        wrapper: "",
        page: "",
        currentPage: "",
      },
    },
    carousel: {
      ariaLabel: {
        delimiter: "",
      },
    },
  },
  drop: {
    currentSellCount: "Selling",
    white_list_description:
      "This Drop only serve to specific users.For details, see below.",
    white_list: "White list",
    click_detail: "Detail",
    sell_time_label: "Drop time",
    mini_seq: "Latest",
    total_supply: "Total supply",
    issuer: "Issuer",
    creator: "Creator",
    source: "From",
    collection: "Collection",
    gender: "Gender",
    royalty_fee: "Royalty Fee",
    level: "Level",
    gender_map: {
      male: "Male",
      female: "Female",
      unisex: "Unisex",
    },
    btn_buy: "Buy",
    btn_sell_out: "SOLD OUT",
    physical: "Real Goods",
    style_code: "Style code",
    physical_color: "Color",
    physical_size: "Size",
    delivery_time: "Redeemable Time",
    physical_title: "Real Goods Information",
    buy_title: "Buy",
    buy_title_sell_out: "SOLD OUT",
    price: "Unit Price",
    count: "Amount",
    total: "Total",
    get_tips: "Expected to get ",
    goods_not_found: "未查询到出售信息",
    not_support_buy: " It’s not on sale",
    buy_ok: "Buy successfully",
    nft_error: "NFT查询出错",
    transfing: "Transfering",
    onsale: "Onsale",
    buy_time: "Received time",
    loading: "Loading",
    no_more_data: "",
    no_data: "no drop",
    // sell_not_start: '未开始',
    btn_sell: "Sell",
    btn_cannot_sell: "Invalid",
    nft_serialid_notice: "The number is first-come-first-served.",
    remain_time_notice_0: "Start sale in",
    remain_time_notice_1: " Days",
    remain_time_notice_2: "",
    remain_time_notice_3: "",
    agree: "OK",
    carousel_title: "Recent Drops",
    plus_tips:
      "This issue of Drop contains product：Plus .\nEach NFT in Plus will contain 1-4 Plus and the corresponding buff.\nThe higher the product level, the higher the Plus buff.",
    plus_tips2:
      "Each NFT randomly contains 1-4 Plus and the corresponding buff.\nThe higher the product level, the higher the Plus buff.",
    plus_all: "ALL",
    plus_label: "PLUS",
  },
  bag: {
    first_category: "Type",
    second_category: "Category",
    wallet: "Wallet",
    logout: "Logout",
    phone: "phone number",
    email: "email",
    create_ownerid: "Create ID",
    ownerid_tips: "ID cannot be modified after creation",
    confirm: "OK",
    cancel: "Cancel",
    set_ownerid_ok: " ID has been created successfully",
    trans_records: "Transaction History",
    deposit: "Deposit",
    pay_time: "Pay successfully",
    account_time: "Deposit successfully",
    deposit_record: "Deposit History",
    blockchain: "Network",
    address: "Wallet Address",
    address_qr: "Wallet Address QR Code",
    copy: "Copy",
    download_qr: "Save Image",
    wallet_warn_1:
      "1. Please don't deposit any other digital assets except the assets from 【{NET}】to the above address. Otherwise, you may lose your assets permanently.",
    wallet_warn_2:
      "2. Depositing to the above address requires confirmation of the entire network. Please be patient.",
    no_nft: "You haven't owned any NFTs yet.",
    no_trade: "No transaction yet.",
    no_deposit: "No deposit yet.",
  },
  category: {
    fashion: "Fashion",
    art: "Art",
    collectibles: "Collectibles",
    music: "Music",
    gaming: "Gaming",
  },
  fashion: {
    shoes: "Sneaker",
    tees: "Tee",
    hoodies: "Hoodie",
    pants: "Pants",
    caps: "Hat",
    bags: "Bag",
    necklaces: "Necklace",
    tattoo: "Tatoo",
  },
  art: {
    image: "Image",
    video: "Video",
    "3d": "3D",
    d3d: "3D Animated",
  },
  collectibles: {
    image: "Image",
    video: "Video",
    "3d": "3D",
    d3d: "3D Animated",
  },
  music: {
    music: "Music",
    mv: "MV",
  },
  gaming: {
    role: "Avatar",
    pet: "Pet",
    mount: "Ride",
    furniture: "Furniture",
    prop: "Props",
  },
  utils: {
    copy_success: "Copy successfully",
    copy_fail: "Copy fail",
  },
  转入成功: "Deposit successfully",
  转出成功: "Withdraw successfully",
  充值成功: "Deposit successfully",
  充值: "Deposit ",
  trade: "trade",
  notice: {
    title_info: "Info",
    title_attention: "Attention",
    money_not_enough: "Insufficient wallet balance",
    cancel: "Cancel",
    ok: "OK",
    go_recharge: "Deposit",
    copy_ok: "Copy successfully",
    sell_notice: "Coming soon, so stay tuned",
    buy_num_limit: "Limited to {buy_limit} pieces. You have exceeded it.",
  },
  alert: {
    details: "details",
  },
  loginOwner: {
    loginOwnerSubTitle: "Creat your KOOOLA ID",
    loginOwnerPrompt:
      "As your  only identification,KOOOLA ID cannot be modified once created.",
    loginOwnerValdiateError:
      "KOOOLA ID must contain at least 4 letters and numbers.",
    loginButton: "set up",
    setUpSuccess: "Set up successfully",
  },
  walletInfo: {
    prompt: "Log in to purchase.",
    email: "Email",
    phone: "Phone",
  },
  promptDialog: {
    content: "Please finish payment in the new page.",
    button: "OK",
  },
};
