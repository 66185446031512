export default {
  header: {
    Home: "首页",
    Drop: "发售",
    NFT: "NFT",
    wallet: "钱包",
    EN: "英",
    CN: "中",
    Foundation: "基金会",
  },
  footer: {
    kooola_text: "Powered by REALY",
  },
  home: {
    app_download: "APP下载",
    baas_login: "Baas登录",
  },
  foundation: {
    litepaper: "白皮书",
  },
  login: {
    title: "欢迎来到KOOOLA!",
    notice_phone: "未注册的手机号验证后将自动创建账号",
    notice_email: "未注册的邮箱验证后将自动创建账号",
    phone: "手机",
    email: "邮箱",
    account_title: "账号密码登录",
    input_phone: "请输入你的手机号",
    input_verify_code: "请输入验证码",
    input_account: "请输入你的ID/手机号/邮箱",
    input_passwd: "请输入密码",
    get_verify_code: "获取验证码",
    login_btn: "登录",
    switch_account_login: "密码登录",
    switch_verifycode_login: "验证码登录",
    annous_1: "登录注册即表示同意",
    annous_2: "《隐私政策》",
    input_email: "请输入你的邮箱",
    relogin: "请重新登录",
    email_invalid: "邮箱格式不正确",
    verify_code_invalid: "请输入验证码",
    verify_code_need: "请先获取验证码",
    phone_invalid: "手机号码格式不正确",
    login_ok: "登录成功",
    send_verify_ok_phone: "验证码已发往你的手机",
    send_verify_ok_email: "验证码已发往你的邮箱",
    logout_ok: "退出登陆",
    token_invalid: "登陆已过期，请重新登陆",
  },
  $vuetify: {
    noDataText: "空",
    pagination: {
      ariaLabel: {
        previous: "上一页",
        next: "下一页",
        wrapper: "",
        page: "",
        currentPage: "",
      },
    },
    carousel: {
      ariaLabel: {
        delimiter: "",
      },
    },
  },
  drop: {
    currentSellCount: "当前在售",
    white_list_description: "本期发售仅对符合条件的用户开放，详情请见下文。",
    white_list: "白名单发售",
    click_detail: "点击查看",
    sell_time_label: "发售时间",
    mini_seq: "最新编号",
    total_supply: "发行总量",
    issuer: "发行方",
    creator: "创作者",
    source: "来源",
    collection: "系列",
    gender: "性别",
    royalty_fee: "版税",
    level: "等级",
    gender_map: {
      male: "男",
      female: "女",
      unisex: "通用",
    },
    btn_buy: "购买",
    btn_sell_out: "已售罄",
    physical: "实物",
    style_code: "货号",
    physical_color: "颜色",
    physical_size: "实物尺码",
    delivery_time: "提货时间",
    physical_title: "本商品包含实物",
    buy_title: "购买",
    buy_title_sell_out: "已售罄",
    price: "价格",
    count: "数量",
    total: "总计",
    get_tips: "预计得到编号",
    goods_not_found: "未查询到出售信息",
    not_support_buy: "当前商品不支持购买",
    buy_ok: "购买成功",
    nft_error: "NFT查询出错",
    transfing: "转移中",
    onsale: "出售中",
    buy_time: "到账时间",
    loading: "加载中",
    no_more_data: "",
    no_data: "没有drop发售",
    // sell_not_start: '未开始',
    btn_sell: "出售",
    btn_cannot_sell: "不可出售",
    nft_serialid_notice: "较小编号，按支付成功时间，先到先得",
    remain_time_notice_0: "",
    remain_time_notice_1: "天",
    remain_time_notice_2: "后开售",
    remain_time_notice_3: "敬请期待",
    agree: "确认",
    carousel_title: "Recent Drops",
    plus_tips:
      "本期Drop包含Plus商品。\nPlus商品中的每枚NFT将包含1-4个Plus，及相应的加成数值。\n商品等级越高，Plus加成越高。",
    plus_tips2:
      "每枚NFT均随机包含1-4个Plus，\n及Plus的加成数值商品等级越高，Plus加成越高。",
    plus_all: "全部",
    plus_label: "PLUS",
  },
  bag: {
    first_category: "类型",
    second_category: "分类",
    wallet: "钱包",
    logout: "退出",
    phone: "手机号",
    email: "邮箱",
    create_ownerid: "创建ID",
    ownerid_tips: "ID是你身份的唯一凭证，创建后不可修改",
    confirm: "确认",
    cancel: "取消",
    set_ownerid_ok: "设置确权ID成功",
    trans_records: "交易记录",
    deposit: "充值",
    pay_time: "支付成功",
    account_time: "到账时间",
    deposit_record: "充值记录",
    blockchain: "网络",
    address: "钱包地址",
    address_qr: "钱包地址二维码",
    copy: "复制地址",
    download_qr: "保存二维码至本地",
    wallet_warn_1:
      "1. 请勿向上述地址转入任何非您选择的【{NET}】资产，否则资产将不可找回；",
    wallet_warn_2:
      "2. 将资产转入上述地址后，需要整个网络节点的确认，敬请耐心等待；",
    no_nft: "你还未持有NFT",
    no_trade: "还未有商品交易记录",
    no_deposit: "还未有充值记录",
  },
  category: {
    fashion: "虚拟服饰",
    art: "数字艺术",
    collectibles: "数字收藏品",
    music: "音乐",
    gaming: "游戏",
  },
  fashion: {
    shoes: "球鞋",
    tees: "T恤",
    hoodies: "卫衣",
    pants: "裤子",
    caps: "帽子",
    bags: "包",
    necklaces: "项链",
    tattoo: "纹身",
  },
  art: {
    image: "图片",
    video: "视频",
    "3d": "3D",
    d3d: "动态3D",
  },
  collectibles: {
    image: "图片",
    video: "视频",
    "3d": "3D",
    d3d: "动态3D",
  },
  music: {
    music: "音乐",
    mv: "MV",
  },
  gaming: {
    role: "角色",
    pet: "宠物",
    mount: "坐骑",
    furniture: "家具",
    prop: "道具",
  },
  utils: {
    copy_success: "复制成功",
    copy_fail: "复制失败",
  },
  转入成功: "转入成功",
  转出成功: "转出成功",
  充值成功: "充值成功",
  充值: "充值",
  notice: {
    title_info: "提示",
    title_attention: "注意",
    money_not_enough: "钱包余额不足",
    cancel: "取消",
    ok: "确认",
    go_recharge: "去充值",
    copy_ok: "复制成功",
    sell_notice: "即将开启，敬请期待",
    buy_num_limit:
      "本期活动限购{buy_limit}件。\n包含本次购买，你的购买数量已超限。",
  },
  alert: {
    details: "了解详情",
  },
  loginOwner: {
    loginOwnerSubTitle: "创建你的KOOOLA ID",
    loginOwnerPrompt: "KOOOLA ID是你的唯一身份标识。一旦创建，不可修改。",
    loginOwnerValdiateError: "KOOOLA ID支持字母及数字，最少4位",
    loginButton: "建立",
    setUpSuccess: "KOOOLA ID创建成功",
  },
  walletInfo: {
    prompt: "登录后继续购买",
    email: "邮箱",
    phone: "手机",
  },
  promptDialog: {
    content: "请在新页面完成订单支付。",
    button: "确认",
  },
};
