<template>
  <!-- App.vue -->
  <v-app style="min-width: 960px">
    <top-header />

    <!-- Sizes your content based upon application components -->
    <v-main>
      <!-- Provides the application the proper gutter -->
      <v-container class="pa-0 ma-0" fluid>
        <!-- If using vue-router -->
        <router-view />
      </v-container>
    </v-main>

    <v-footer
      app
      height="88px"
      absolute
      class="ma-0 pa-0"
      style="background: #f9f9f9"
    >
      <!-- -->
      <my-footer />
    </v-footer>
    <notifications
      id="notification-container"
      position="top center"
      style="top: 20px"
    />
  </v-app>
</template>

<script>
import TopHeader from "@/components/TopHeader.vue";
import MyFooter from "./components/MyFooter.vue";
import GlobalVue from "./common/global/GlobalVue";
export default {
  components: {
    TopHeader,
    MyFooter,
  },
  created() {
    GlobalVue.vueInstance = this;
  },
};
</script>
>

<style scoped>
/* .container{
  padding: 0;
} */
</style>
<style>
.theme--light.v-application {
  color: rgba(0, 0, 0, 0) !important;
}
.theme--light.v-image {
  color: rgba(0, 0, 0, 0) !important;
}
.theme--light.v-btn.v-btn--has-bg {
  /* background-color: initial; */
}
.v-dialog {
  overflow-x: visible;
  box-shadow: none !important;
}
.v-btn::before {
  color: transparent !important;
}
body {
  overflow: auto;
}
#app {
  min-width: 960px;
}
.v-dialog {
  overflow: visible !important;
}
</style>
