<template>
  <v-dialog
    v-model="dialog"
    content-class="login-dlg-content"
    class="pa-0 ma-0"
    max-width="480px"
    style="overflow: visible"
    persistent
  >
    <div class="dlg-cross-box">
      <v-img
        :src="require('@/assets/icon/cross@3x.png')"
        max-height="28px"
        max-width="28px"
        @click="
          () => {
            dialog = false;
          }
        "
      />
    </div>
    <v-card flat tile style="border-radius: 18px !important">
      <v-card-text class="ma-0 pa-0" style="padding-bottom: 25px !important">
        <v-row no-gutters style="margin-top: 38px">
          <v-col class="d-flex justify-center align-center">
            <v-img
              :src="require('@/assets/emoji/Star-Struck@3x.png')"
              max-width="30px"
              max-height="30px"
              class="d-inline-block"
            />
            <div class="login-title ml-2 d-inline-block">
              {{ $t("login.title") }}
            </div>
          </v-col>
        </v-row>
        <!-- 手机邮箱按钮 -->
        <v-row
          v-show="loginType === 1 || loginType === 2"
          justify="center"
          no-gutters
          class="mt-4"
        >
          <v-col
            cols="12"
            class="text-center d-flex justify-center"
            style="max-width: 204px"
          >
            <div class="btn-groups">
              <v-btn
                :class="[
                  { 'btn-active': loginType === 2 },
                  'login-select-btn',
                  'phone-btn',
                ]"
                @click="handleLoginType(2)"
              >
                {{ $t("login.email") }}
              </v-btn>
              <v-btn
                :class="[
                  { 'btn-active': loginType === 1 },
                  'login-select-btn',
                  'email-btn',
                ]"
                @click="handleLoginType(1)"
              >
                {{ $t("login.phone") }}
              </v-btn>
            </div>
          </v-col>
        </v-row>
        <!-- 账号密码登录标题 -->
        <v-row
          v-show="loginType === 3"
          justify="center"
          no-gutters
          class="mt-4"
        >
          <v-col
            cols="12"
            class="text-center d-flex justify-center"
            style="max-width: 204px"
          >
            <div class="account-title d-flex align-center justify-center">
              {{ $t("login.account_title") }}
            </div>
          </v-col>
        </v-row>
        <!-- 说明 -->
        <v-row
          v-show="loginType === 1 || loginType === 2"
          no-gutters
          class="mt-2"
          justify="center"
        >
          <v-col
            cols="12"
            class="login-notice-text text-center"
            style="max-width: 294px"
          >
            <span v-if="loginType === 1">{{ $t("login.notice_phone") }}</span>
            <span v-if="loginType === 2">{{ $t("login.notice_email") }}</span>
          </v-col>
        </v-row>
        <!-- 电话号码 -->
        <v-row
          v-show="loginType === 1"
          justify="center"
          align-content="center"
          no-gutters
          class="mt-8"
        >
          <v-col
            class="text-center d-flex align-center"
            style="max-width: 340px"
          >
            <div class="phone-left d-inline-flex align-center">
              <v-autocomplete
                v-model="arenaCode"
                :items="countryCode.map((value) => value.phone_code)"
                dense
                cache-items
                hide-details
              />
            </div>
            <div class="phone-right d-inline-flex align-center">
              <v-text-field
                v-model="loginPhone"
                hide-details
                clearable
                :placeholder="$t('login.input_phone')"
              />
            </div>
          </v-col>
        </v-row>
        <!-- 邮箱 -->
        <v-row
          v-show="loginType === 2"
          justify="center"
          no-gutters
          class="mt-8"
        >
          <v-col cols="12" style="max-width: 340px">
            <v-text-field
              v-model="loginEmail"
              class="email-input d-flex align-center"
              hide-details
              clearable
              :placeholder="$t('login.input_email')"
            />
          </v-col>
        </v-row>
        <!-- 账号 -->
        <v-row
          v-show="loginType === 3"
          justify="center"
          no-gutters
          class="mt-7"
        >
          <v-col cols="12" style="max-width: 340px">
            <v-text-field
              v-model="loginAccount"
              class="email-input d-flex align-center"
              hide-details
              clearable
              :placeholder="$t('login.input_account')"
            />
          </v-col>
        </v-row>
        <!-- 验证码 -->
        <v-row
          v-show="loginType === 1 || loginType === 2"
          justify="center"
          no-gutters
          class="mt-4"
        >
          <v-col cols="10" style="max-width: 240px">
            <v-text-field
              v-model="verifyCode"
              class="verify-code d-flex align-center"
              hide-details
              clearable
              :placeholder="$t('login.input_verify_code')"
            />
          </v-col>
          <v-col style="max-width: 100px">
            <v-btn text class="verify-code-btn" @click="handleGetVerifyCode">
              {{ loginType === 1 ? verifyBtnTextPhone : verifyBtnTextEmail }}
            </v-btn>
          </v-col>
        </v-row>
        <!-- 密码 -->
        <v-row
          v-show="loginType === 3"
          justify="center"
          no-gutters
          class="mt-4"
        >
          <v-col cols="12" style="max-width: 340px">
            <v-text-field
              v-model="loginPassword"
              class="email-input d-flex align-center"
              hide-details
              clearable
              :placeholder="$t('login.input_passwd')"
              type="password"
            />
          </v-col>
        </v-row>
        <!-- 登录 -->
        <v-row justify="center" no-gutters style="margin-top: 30px">
          <v-col style="max-width: 340px">
            <v-btn
              :class="[
                {
                  'login-btn-valid': loginBtnValid,
                },
                'login-btn',
              ]"
              @click="handleLogin"
            >
              <span class="login-btn-text">{{ $t("login.login_btn") }}</span>
            </v-btn>
            <v-btn
              class="switch-btn"
              style="margin-left: 15px"
              @click="handleLoginType(3)"
            >
              {{ switchBtnTitle }}
            </v-btn>
          </v-col>
        </v-row>
        <!-- 隐私条款 -->
        <v-row no-gutters class="pt-3">
          <v-col cols="12" class="text-center">
            <span class="privacy-text">
              {{ $t("login.annous_1") }}
              <router-link
                style="color: rgba(11, 91, 233, 1)"
                to="/privacy"
                target="_blank"
                >{{ $t("login.annous_2") }}</router-link
              >
            </span>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { defineComponent, ref } from "@vue/composition-api";
import countryCode from "@/utils/countrycode";
import i18n from "@/lang";
import { getSms, loginByVerifyCode, loginByUsername } from "@/api/login";
export default defineComponent({
  name: "LoginDlg",
  data: () => ({
    dialog: false,
    loginType: 2, // 1 phone 2 email 3 account/passwd
    countryCode: countryCode,
    valid: true,

    switchBtnTitle: i18n.t("login.switch_account_login"),

    loginAccount: "", // 账号
    loginPassword: "",
    arenaCode: "+86",
    loginPhone: "",
    loginEmail: "",
    verifyCode: "",
    smsId: "",
    countDownPhone: false,
    countDownEmail: false,
    loginBtnValid: false,
    verifyBtnTextPhone: i18n.t("login.get_verify_code"),
    verifyBtnTextEmail: i18n.t("login.get_verify_code"),
  }),
  watch: {
    "$store.getters.showLogin"(_new, old) {
      // console.log(_new, old)
      if (_new && old !== _new) {
        this.dialog = true;
      }
    },
    dialog(_new, old) {
      if (_new === false && old !== _new) {
        this.$store.dispatch("showLoginDlg", false);
      }
    },
    countDownPhone(n) {
      if (n === true) {
        const clock = window.setInterval(() => {
          const delta =
            new Date().getTime() - this.$store.getters.lastVerifyTimePhone;
          if (delta >= 60000) {
            this.verifyBtnTextPhone = this.$t("login.get_verify_code");
            this.countDownPhone = false;
            window.clearInterval(clock);
          } else {
            this.verifyBtnTextPhone = 60 - Math.floor(delta / 1000) + "s";
          }
        }, 1000);
      }
    },
    countDownEmail(n) {
      if (n === true) {
        const clock = window.setInterval(() => {
          const delta =
            new Date().getTime() - this.$store.getters.lastVerifyTimeEmail;
          if (delta >= 60000) {
            this.verifyBtnTextEmail = this.$t("login.get_verify_code");
            this.countDownPhone = false;
            window.clearInterval(clock);
          } else {
            this.verifyBtnTextEmail = 60 - Math.floor(delta / 1000) + "s";
          }
        }, 1000);
      }
    },
    loginAccount(n) {
      if (this.loginType === 3) {
        this.loginBtnValid =
          this.loginAccount &&
          this.loginPassword &&
          this.loginAccount.length > 0 &&
          this.loginPassword.length > 0;
      }
    },
    loginPassword(n) {
      if (this.loginType === 3) {
        this.loginBtnValid =
          this.loginAccount &&
          this.loginPassword &&
          this.loginAccount.length > 0 &&
          this.loginPassword.length > 0;
      }
    },
    loginPhone(n) {
      if (this.loginType === 1) {
        this.loginBtnValid =
          this.loginPhone &&
          this.verifyCode &&
          this.loginPhone.length > 0 &&
          this.verifyCode.length > 0;
      }
    },
    loginEmail(n) {
      if (this.loginType === 2) {
        this.loginBtnValid =
          this.loginEmail &&
          this.verifyCode &&
          this.loginEmail.length > 0 &&
          this.verifyCode.length > 0;
      }
    },
    verifyCode(n) {
      if (this.loginType === 1) {
        this.loginBtnValid =
          this.loginPhone &&
          this.verifyCode &&
          this.loginPhone.length > 0 &&
          this.verifyCode.length > 0;
      } else if (this.loginType === 2) {
        this.loginBtnValid =
          this.loginEmail &&
          this.verifyCode &&
          this.loginEmail.length > 0 &&
          this.verifyCode.length > 0;
      }
    },
    loginType(n) {
      this.loginBtnValid = false;
    },
  },
  created() {
    let delta = new Date().getTime() - this.$store.getters.lastVerifyTimePhone;
    if (delta < 60000) {
      this.countDownPhone = true;
    }
    delta = new Date().getTime() - this.$store.getters.lastVerifyTimeEmail;
    if (delta < 60000) {
      this.countDownEmail = true;
    }
  },
  methods: {
    handleTestClick() {
      loginOwnerSingle.open();
    },
    check_mobile_oversea(mobile, areacode) {
      // 保证不超17位
      var mobilecode = mobile + areacode;
      if (mobilecode.length > 17) {
        return false;
      }
      if (!new RegExp(/^\d{1,17}$/).test(mobile + areacode)) {
        return false;
      }
      return true;
    },
    reset() {
      this.loginAccount = "";
      this.loginPassword = "";
      this.arenaCode = "+86";
      this.loginPhone = "";
      this.loginEmail = "";
      this.verifyCode = "";
      this.smsId = "";
    },
    handleLoginType(tp) {
      this.reset();
      if (tp === 3) {
        if (this.loginType === 3) {
          this.loginType = 1;
          this.switchBtnTitle = i18n.t("login.switch_account_login");
        } else {
          this.loginType = tp;
          this.switchBtnTitle = i18n.t("login.switch_verifycode_login");
        }
      } else {
        this.loginType = tp;
      }
    },
    handleGetVerifyCode() {
      if (this.loginType === 1) {
        // 手机号
        if (this.countDownPhone) {
          return;
        }
        if (
          !this.loginPhone ||
          !this.check_mobile_oversea(this.loginPhone, 0)
        ) {
          this.$notify({
            text: this.$t("login.phone_invalid"),
            type: "error",
          });
          return;
        }
        this.$store.dispatch("SendVerifyCode", "phone");
        this.countDownPhone = true;
        getSms(this.arenaCode, this.loginPhone, null)
          .then((response) => {
            this.$notify({
              text: this.$t("login.send_verify_ok_phone"),
              type: "success",
            });
            this.smsId = response.data.smsid;
          })
          .catch((error) => {
            console.error(error);
          });
      } else if (this.loginType === 2) {
        if (this.countDownEmail) {
          return;
        }
        const email_pattern = /^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/;
        if (!email_pattern.test(this.loginEmail)) {
          this.$notify({
            text: this.$t("login.email_invalid"),
            type: "error",
          });
          this.loginEmail = "";
          return;
        }
        this.$store.dispatch("SendVerifyCode", "email").then(() => {
          this.countDownEmail = true;
          getSms(null, null, this.loginEmail)
            .then((response) => {
              this.smsId = response.data.smsid;
              this.$notify({
                text: this.$t("login.send_verify_ok_email"),
                type: "success",
              });
            })
            .catch((error) => {
              console.error(error);
            });
        });
      }
    },
    loginSuccess(resp) {
      console.log("loginresp", resp);
      this.$store.dispatch("LoginOk", resp.data.token, resp.data.refresh_token);
      this.$store
        .dispatch("GetUserInfo")
        .then((responseUser) => {
          console.log("responseUser", responseUser);
          this.$notify({
            text: this.$t("login.login_ok"),
            type: "success",
          });
          const next = this.$store.getters.showLogin;
          if (typeof next === "string") {
            this.$router.push(next);
          }
          this.dialog = false;
        })
        .catch((err) => console.log(err));
    },
    handleLogin() {
      if (!this.loginBtnValid) {
        return;
      }
      if (this.loginType === 1 || this.loginType === 2) {
        // 手机// 邮箱
        if (!this.smsId) {
          this.$notify({
            text: this.$t("login.verify_code_need"),
            type: "error",
          });
          return;
        }
        if (!this.verifyCode) {
          this.$notify({
            text: this.$t("login.verify_code_invalid"),
            type: "error",
          });
          return;
        }
        const data = {
          username: this.loginType === 1 ? this.loginPhone : this.loginEmail,
          type: this.loginType === 1 ? "phone" : "email",
          token: this.smsId,
          verify: this.verifyCode,
          arena: this.arenaCode,
        };
        loginByVerifyCode(data)
          .then((resp) => {
            this.loginSuccess(resp);
          })
          .catch((err) => {
            console.error(err);
            this.reset();
          });
      } else if (this.loginType === 3) {
        // 密码
        if (!this.loginAccount || !this.loginPassword) {
          return;
        }
        loginByUsername(this.loginAccount, this.loginPassword)
          .then((resp) => {
            this.loginSuccess(resp);
          })
          .catch((err) => console.error(err));
      }
      this.reset();
    },
  },
});
</script>

<style scoped>
>>> .login-dlg-content {
  width: 480px;
  height: 430px;
  background: #ffffff;
  box-shadow: 0px 11px 56px 10px rgba(0, 0, 0, 0.07);
  border-radius: 18px;
  overflow: visible;
}
>>> .v-input__icon--clear {
  width: 22px;
  height: 22px;
  margin-right: 18px;
  margin-top: 0px;
}
>>> .mdi-close::before {
  content: "";
  display: inline-block;
  background: url("~@/assets/icon/delete@3x.png") no-repeat 0 0;
  background-size: 22px 22px;
  width: 22px !important;
  height: 22px !important;
}
>>> .v-icon.v-icon:after {
  width: 24px;
}

>>> .v-text-field {
  margin-top: 0px !important;
  padding-top: 0px !important;
}

.login-title {
  height: 27px;
  font-size: 22px;
  font-weight: bold;
  color: #18191f;
  line-height: 27px;
}

.btn-groups {
  height: 38px;
  width: 204px !important;
  border-radius: 19px !important;
  /* border: 2px solid rgb(86, 6, 106); */
  border: 2px solid rgba(180, 255, 89, 1);
  overflow: visible !important;
  /* box-sizing: content-box; */
}

.btn-groups .v-btn {
  height: 36px !important;
  width: 50% !important;
}

.login-select-btn {
  border-radius: 19px;
  background-color: transparent !important;
  font-size: 14px !important;
  font-weight: bold;
  color: #000000;
  line-height: 24px;
  display: inline-block;
  box-shadow: none !important;
}

.btn-active {
  background: rgba(180, 255, 89, 1) !important;
}

.login-select-btn:not(.btn-active):hover {
  background: rgba(180, 255, 89, 0.2) !important;
}
.login-select-btn:not(.btn-active):hover::before {
  opacity: 1;
  position: absolute;
  left: calc(100% - 16px);
  top: calc(100% - 13px);
  content: none !important;
  display: inline-block;
  background: url("~@/assets/icon/Finger@3x.png") no-repeat 0 0;
  background-size: 19px 20px;
  width: 19px;
  height: 20px;
}

.login-notice-text {
  font-size: 10px;
  font-weight: 400;
  color: #000000;
  line-height: 14px;
  opacity: 0.3;
}

.phone-left {
  border-top-left-radius: 24px;
  border-bottom-left-radius: 24px;
  background: #f4f5f7;
  height: 48px;
  width: 86px !important;
}

.phone-left >>> .v-input__control > .v-input__slot:before {
  border-style: none;
}
.phone-left >>> .v-input__control > .v-input__slot:after {
  border-style: none;
}
.phone-left >>> input {
  text-align: center;
  font-size: 14px;
  color: #000000 !important;
  font-weight: 500;
  line-height: 18px;
  padding: 0px !important;
  padding-left: 20px !important;
}

.phone-left >>> .mdi-menu-down::before {
  content: "";
  display: inline-block;
  background: url("~@/assets/icon/arrow-down@3x.png") no-repeat 0 0;
  background-size: 14px 9px;
  width: 14px;
  height: 9px;
}

.phone-left >>> .v-input__icon {
  height: 100%;
  width: 100%;
  min-width: 0 !important;
  padding-top: 5px;
}

.phone-right {
  border-top-right-radius: 24px;
  border-bottom-right-radius: 24px;
  background-color: #f4f5f7 !important;
  height: 48px;
  width: 252px !important;
}
.phone-right::before {
  content: "";
  width: 2px;
  height: 30px;
  background: transparent;
  margin-left: 10px;
}
.phone-right >>> input {
  font-size: 14px;
  font-weight: 500;
  color: #000000 !important;
  line-height: 18px;
  padding: 0px !important;
  margin-top: 0px !important;
}
.phone-right >>> .v-input {
  margin-top: 0px;
  padding-top: 0px;
}
.phone-right >>> .v-input__control > .v-input__slot:before {
  border-style: none;
}
.phone-right >>> .v-input__control > .v-input__slot:after {
  border-style: none;
}

.verify-code {
  height: 48px;
  width: 230px;
  background: #f4f5f7;
  border-radius: 30px;
  margin-top: 0px;
}
.verify-code >>> .v-input__control > .v-input__slot:before {
  border-style: none;
}
.verify-code >>> .v-input__control > .v-input__slot:after {
  border-style: none;
}
.verify-code >>> input {
  padding: 0px;
  font-size: 14px;
  font-weight: 500;
  color: #000000;
  line-height: 18px;
  padding-left: 20px;
}

.verify-code-btn {
  border-radius: 12px;
  width: 100% !important;
  height: 48px !important;
  font-size: 14px;
  font-weight: bold;
  color: #000000;
  line-height: 18px;
  text-transform: capitalize;
}
.verify-code-btn:hover {
  background: #ededed !important;
}
.switch-btn {
  width: 135px;
  height: 48px !important;
  background: #ffffff !important;
  border-radius: 12px;
  border: 2.5px solid #000000;
  text-transform: capitalize;
}
.switch-btn:hover {
  background: #ededed !important;
}
.email-input {
  width: 340px;
  height: 48px;
  background: #f4f5f7;
  border-radius: 24px;
}
.email-input >>> input {
  padding: 0px;
  font-size: 14px;
  font-weight: 400;
  color: #000000;
  line-height: 20px;
  padding-left: 24px;
}
.email-input >>> .v-input__control > .v-input__slot:before {
  border-style: none;
}
.email-input >>> .v-input__control > .v-input__slot:after {
  border-style: none;
}
.email-input >>> .v-input {
  margin-top: 0px !important;
  padding-top: 0px !important;
}
.account-title {
  background-color: rgba(180, 255, 89, 1);
  width: 204px;
  height: 38px;
  border-radius: 19px;
  border: 2px solid rgba(180, 255, 89, 1);
  font-size: 14px;
  font-weight: 600;
  color: #000000;
  line-height: 24px;
  text-transform: capitalize;
}
.account-input >>> .v-input__control > .v-input__slot:before {
  border-style: none;
}
.account-input >>> .v-input__control > .v-input__slot:after {
  border-style: none;
}
.login-btn {
  width: 190px;
  height: 48px !important;
  border-radius: 12px;
  background: rgba(180, 255, 89, 1) !important;
  opacity: 0.3;
}
.login-btn-valid {
  opacity: 1 !important;
}
.login-btn-text {
  height: 24px;
  font-size: 16px;
  font-weight: 600;
  color: #000000;
  line-height: 24px;
  text-transform: capitalize;
}
.privacy-text {
  height: 14px;
  font-size: 10px;
  font-weight: 400;
  color: #9497a3;
  line-height: 14px;
}
.dlg-cross-box {
  background-color: rgba(0, 0, 0, 0) !important;
  position: relative;
  left: calc(100% + 18px);
  top: 0px;
  width: 28px;
  height: 28px;
  float: left;
}
</style>
