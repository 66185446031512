import request from '@/utils/request'

export function getSms(arena, phone, email) {
  return request({
    url: '/sms',
    method: 'get',
    params: { arena, phone, email }
  })
}

export function loginByVerifyCode(data) {
  return request({
    url: '/user/signup',
    method: 'post',
    data
  })
}

export function loginByUsername(username, password) {
  const data = {
    username,
    password
  }
  return request({
    url: '/user/login/web',
    method: 'post',
    data
  })
}

export function logout() {
  return request({
    url: '/login/logout',
    method: 'post'
  })
}

export function getUserInfo() {
  return request({
    url: '/user/profile',
    method: 'get'
  })
}

export function refreshToken(data) {
  return request({
    url: '/game/login/refresh',
    method: 'post',
    data
  })
}
