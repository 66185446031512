import Cookies from 'js-cookie'

const TokenKey = 'Realy-Token'
const retokenKey = 'Realy-Token-re'

export function getToken() {
  return Cookies.get(TokenKey)
}

export function getReToken() {
  return Cookies.get(retokenKey)
}

export function setToken(token, retoken) {
  Cookies.set(TokenKey, token)
  Cookies.set(retokenKey, retoken)
}

export function removeToken() {
  Cookies.remove(TokenKey)
  Cookies.remove(retokenKey)
}
