import request from "@/utils/request";
import Querstring from "./Querystring";

export default class MyApi {
  async get(url, dataInfo) {
    let response = await request({
      url: dataInfo ? url + Querstring.ConvertToString(dataInfo) : url,
      method: "get",
    });
    let { code } = response;
    if (code == 200) {
      return response;
    }
  }
  async post(url, datainfo) {
    let response = await request({
      url,
      method: "post",
      data: datainfo,
    });
    console.log("response", response);
    let { data, code } = response;
    if (code == 0) {
      if (data) {
        return data;
      } else {
        return true;
      }
    }
  }
}
