import Vue from 'vue'
import VueRouter from 'vue-router'
// import Home from '@/views/Home.vue'
import DropList from '@/views/DropList.vue'
import store from '@/store'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/drop'
  },
  {
    path: '/home',
    redirect: '/drop'
  },
  // {
  //   path: '/home',
  //   name: 'Home',
  //   component: Home
  // },
  // {
  //   path: '/foundation',
  //   name: 'Foundation',
  //   component: () => import('../views/Foundation.vue')
  // },
  {
    path: '/drop',
    name: 'Drop',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    // component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
    component: DropList
  }, {
    path: '/nft',
    meta: { requiresAuth: true },
    component: () => import('../views/Bag.vue')
  }, {
    path: '/wallet',
    meta: { requiresAuth: true },
    component: () => import('../views/Bag.vue')
  }, {
    path: '/profile',
    meta: { requiresAuth: true },
    component: () => import('../views/Bag.vue')
  }, {
    path: '/nft/:id',
    meta: { requiresAuth: true },
    component: () => import('../views/NftDetail.vue')
  }, {
    path: '/drop/activity/:id',
    component: () => import('../views/Activity.vue')
  }, {
    path: '/drop/:id',
    component: () => import('../views/Drop.vue')
  }, {
    path: '/privacy',
    component: () => import('../views/Privacy.vue')
  }, {
    path: '/404',
    component: () => import('../views/404.vue')
  }, {
    path: '*',
    redirect: '/404'
  }
]

const router = new VueRouter({
  routes
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!store.getters.token) {
      next(false)
      store.dispatch('showLoginDlg', to.fullPath)
    } else {
      next()
    }
  } else {
    next()
  }
})

export default router
