import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from '@/plugins/vuetify'
import i18n from './lang' // Internationalization
import Notifications from 'vue-notification'
import hevueImgPreview from 'hevue-img-preview'
import '@/assets/css/common.css'
import VuetifyDialog from 'vuetify-dialog'
import 'vuetify-dialog/dist/vuetify-dialog.min.css'
import VueClipboard from 'vue-clipboard2'
import VueCompositionAPI from "@vue/composition-api";

Vue.config.productionTip = false
Vue.use(VueCompositionAPI, { jsx: { enable: true } });
Vue.use(Notifications)
Vue.use(hevueImgPreview)
Vue.use(VuetifyDialog, { context: { vuetify }})
Vue.use(VueClipboard)

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: h => h(App)
}).$mount('#app')
