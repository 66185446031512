import Cookies from 'js-cookie'

const LastVerifyTimeEmail = 'verifyemail'
const LastVerifyTimePhone = 'verifyphone'

export function getLastVerifyTime(type) {
  if (type === 'phone') {
    return Cookies.get(LastVerifyTimePhone)
  } else {
    return Cookies.get(LastVerifyTimeEmail)
  }
}

export function setLastVerifyTime(type, tm) {
  if (type === 'phone') {
    Cookies.set(LastVerifyTimePhone, tm)
  } else {
    Cookies.set(LastVerifyTimeEmail, tm)
  }
}
