import { LoginOwnerLocalLang, StoreActionsEnum } from "./enum";
import { LoginOwnerService } from "./service";
import i18n from "../../lang";
import MyMessage from "../../common/utils/MyMessage";
import { MyMessageEnum } from "../../common/enum/MyMessageEnum";
import store from "../../store/index.js";

export class LoginOwnerController {
  /**
   * @description 登出
   */
  logout({ logoutCallback }) {
    //vuex全局发送登出
    store.dispatch(StoreActionsEnum.Logout);
    //消息提示
    MyMessage.showMessage(
      i18n.t(LoginOwnerLocalLang.Logout),
      MyMessageEnum.Success
    );
    logoutCallback();
  }
  /**
   * @description 注册ownerId
   * @param {{ownerId:string,succesCallback:Function,errorCallback:Function}} params
   * ownerId:注册的ownerId
   * succesCallback:成功回调
   * errorCallback:失败回调函数
   */
  async register({ ownerId, succesCallback, errorCallback } = {}) {
    if (this.validate(ownerId)) {
      let service = new LoginOwnerService();
      if (await service.registerOwnerId({ ownerId })) {
        MyMessage.showMessage(
          i18n.t(LoginOwnerLocalLang.SetUpSuccess),
          MyMessageEnum.Success
        );
        succesCallback();
      }
    } else {
      /**
       * 正则校验失败返回本地化报错信息
       */
      errorCallback({
        error: i18n.t(LoginOwnerLocalLang.Error),
      });
    }
  }

  /**
   * @description ownerId 规则校验
   * @param {string} value 校验的值
   * @returns {boolean} 是否过了校验
   */
  validate(value) {
    console.log(value);
    let isFlag = false;
    // const reg = /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{4,}$/i;
    const reg = /^[0-9A-Za-z]{4,}$/i;
    if (reg.test(value)) {
      isFlag = true;
    }
    return isFlag;
  }
}
