import { getWalletInfo } from '@/api/user'

const wallet = {
  state: {
    coins: {}
  },
  mutations: {
    SET_COIN: (state, coins) => {
      for (const k in state.coins) {
        state.coins[k] = null
      }
      for (const k in coins) {
        state.coins[k] = coins[k]
      }
    }

  },
  actions: {
    GetUserWallet({ commit, state }) {
      return new Promise((resolve, reject) => {
        getWalletInfo().then(response => {
          const data = response.data
          const coins = {}
          data.forEach(element => {
            coins[element.assetType] = element.balanceAvailable
          })
          commit('SET_COIN', coins)
          resolve(response)
        }).catch(error => {
          reject(error)
        })
      })
    }
  }
}

export default wallet
