import request from '@/utils/request'

export function getDropList(page, pagecount) {
  return request({
    url: '/web/drop/list',
    method: 'get',
    params: { page, pagecount }
  })
}

export function getDropDetail(activityNo) {
  return request({
    url: '/web/drop/detail',
    method: 'get',
    params: { activityNo }
  })
}

export function getMallDetail(skc_code) {
  return request({
    url: '/web/mall/detail',
    method: 'get',
    params: { skc_code }
  })
}

export function getMallMerchantDetail(activity_no) {
  return request({
    url: '/web/mall/merchant/detail',
    method: 'get',
    params: { activity_no }
  })
}

export function getPayList(data) {
  return request({
    url: '/game/mall/paylist',
    method: 'post',
    data
  })
}

export function mallBuy(data) {
  return request({
    url: '/game/mall/buy',
    method: 'post',
    data
  })
}

export function nftList(data) {
  return request({
    url: '/game/bag/list',
    method: 'get',
    params: data
  })
}

export function nftCountByType() {
  return request({
    url: '/game/bag/nft_count_by_category',
    method: 'get'
  })
}

export function getNftDetail(nftid) {
  return request({
    url: '/game/bag/detail',
    method: 'get',
    params: { nftid }
  })
}

export function bannerList() {
  return request({
    url: '/web/banner/list',
    method: 'get'
  })
}
