import request from '@/utils/request'

export function updateUserInfo(data) {
  return request({
    url: '/game/user/update',
    method: 'post',
    data
  })
}

export function getWalletInfo() {
  return request({
    url: '/game/user/wallet',
    method: 'get'
  })
}

export function getWalletTrans(params) {
  return request({
    url: '/game/user/wallet_transaction',
    method: 'get',
    params
  })
}

