import { logout, getUserInfo, refreshToken } from "@/api/login";
import { getToken, getReToken, setToken, removeToken } from "@/utils/auth";
import { getLastVerifyTime, setLastVerifyTime } from "@/utils/cookiesopt";
import { LoginControllerSingle } from "../../controller/LoginControllerSingle";
const loginController = LoginControllerSingle.CreateInstance();
const user = {
  state: {
    showLoginOwner: false,
    showLogin: false,
    token: getToken(),
    retoken: getReToken(),
    name: "",
    ownerid: "",
    avatar: require("@/assets/icon/user@3x.png"),
    phone: "",
    email: "",
    lastVerifyTimePhone: getLastVerifyTime("phone") || 0,
    lastVerifyTimeEmail: getLastVerifyTime("email") || 0,
  },
  mutations: {
    SHOW_LOGINOWNER: (state, flag) => {
      state.showLoginOwner = flag;
    },
    SHOW_LOGIN: (state, flag) => {
      state.showLogin = flag;
    },
    SET_OWNERID: (state, ownerid) => {
      state.ownerid = ownerid;
    },
    SET_NAME: (state, name) => {
      state.name = name;
    },
    SET_AVATAR: (state, avatar) => {
      if (!avatar) {
        state.avatar = require("@/assets/icon/user@3x.png");
        return;
      }
      state.avatar = avatar;
    },
    SET_PHONE: (state, phone) => {
      state.phone = phone;
    },
    SET_EMAIL: (state, email) => {
      state.email = email;
    },
    SET_OWNEID: (state, ownerid) => {
      state.ownerid = ownerid;
    },
    SET_TOKEN: (state, token) => {
      state.token = token;
    },
    SET_RETOKEN: (state, retoken) => {
      state.retoken = retoken;
    },
    SET_VERIFY_TIME_PHONE: (state, tm) => {
      state.lastVerifyTimePhone = tm;
    },
    SET_VERIFY_TIME_EMAIL: (state, tm) => {
      state.lastVerifyTimeEmail = tm;
    },
  },
  actions: {
    showLoginOwnerDlg({ commit }, flag) {
      commit("SHOW_LOGINOWNER", flag);
    },
    showLoginDlg({ commit }, flag) {
      commit("SHOW_LOGIN", flag);
    },
    LoginOk({ commit }, token, retoken) {
      commit("SET_VERIFY_TIME_PHONE", 0);
      commit("SET_VERIFY_TIME_EMAIL", 0);
      commit("SET_TOKEN", token);
      commit("SET_RETOKEN", retoken);
      setToken(token, retoken);
    },
    // 获取用户信息
    GetUserInfo({ commit, state }) {
      return new Promise((resolve, reject) => {
        this.dispatch("GetUserWallet");
        getUserInfo()
          .then((response) => {
            const data = response.data;
            loginController.checkOwnerId(data);
            commit("SET_NAME", data.name);
            commit("SET_AVATAR", data.avatar);
            commit("SET_OWNERID", data.ownerid);
            commit("SET_PHONE", data.phone);
            commit("SET_EMAIL", data.email);
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    // 登出
    LogOut({ commit, state }) {
      return new Promise((resolve, reject) => {
        logout(state.token)
          .then(() => {
            commit("SET_TOKEN", "", "");
            commit("SET_AVATAR", "");
            removeToken();
            resolve();
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    // 前端 登出
    FedLogOut({ commit }) {
      return new Promise((resolve) => {
        commit("SET_TOKEN", "", "");
        commit("SET_AVATAR", "");
        removeToken();
        resolve();
      });
    },
    SendVerifyCode({ commit }, type) {
      return new Promise((resolve, reject) => {
        const tm = new Date().getTime();
        if (type === "phone") {
          commit("SET_VERIFY_TIME_PHONE", tm);
        } else {
          commit("SET_VERIFY_TIME_EMAIL", tm);
        }
        setLastVerifyTime(type, tm);
        resolve();
      });
    },
    // 刷新toekn
    RefreshToken({ commit, state }) {
      return new Promise((resolve) => {
        refreshToken({ refresh_token: state.retoken }).then((resp) => {
          commit("SET_TOKEN", resp.data.token);
          commit("SET_RETOKEN", resp.data.refresh_token);
          setToken(resp.data.token, resp.data.refresh_token);
          resolve();
        });
      });
    },
  },
};

export default user;
