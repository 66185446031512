const getters = {
  language: state => state.app.language,
  size: state => state.app.size,
  device: state => state.app.device,
  showLogin: state => state.user.showLogin,
  showLoginOwner: state => state.user.showLoginOwner,
  avatar: state => state.user.avatar,
  phone: state => state.user.phone,
  email: state => state.user.email,
  brandMap: state => state.brand.brandMap,
  token: state => state.user.token,
  ownerid: state => state.user.ownerid,
  coins: state => state.wallet.coins,
  lastVerifyTimeEmail: state => state.user.lastVerifyTimeEmail,
  lastVerifyTimePhone: state => state.user.lastVerifyTimePhone
}
export default getters
