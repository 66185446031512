<template>
  <v-card
    class="ma-0 pa-0 drop-base"
    hover
    flat
    color="rgba(0,0,0,0)"
    @click="dropDetail()"
  >
    <v-card-text
      class="image-box fill-height"
      :style="{
        'background-image':
          'linear-gradient(rgba(0,0,0,0.1), rgba(0,0,0,0.1)), url(\'' +
          (cover || bgImage) +
          '\')',
      }"
    >
      <!-- 遮罩 -->
      <!-- brand logo -->
      <v-row justify="space-around" style="margin-top: 192px" no-gutters>
        <v-col cols="12">
          <v-card
            tile
            max-height="110px"
            max-width="110px"
            class="rounded-circle mx-auto px-auto"
          >
            <v-img
              :src="brandLogo"
              class="rounded-circle"
              max-height="110px"
              max-width="110px"
              aspect-ratio="1"
            />
          </v-card>
        </v-col>
      </v-row>
      <!-- 标题 -->
      <v-row no-gutters justify="center" style="margin-top: 49px">
        <v-col cols="10" lg="8" xl="6" class="text-center" align-self="center">
          <span class="goods-title">{{ drop_title }}</span>
        </v-col>
      </v-row>
      <!-- 发售时间 -->
      <v-row justify="center" no-gutters style="margin-top: 14px">
        <v-col class="text-center" align-self="center">
          <div class="sell-time-bg mx-auto algin-center">
            <span class="sell-time-title">{{
              $t("drop.sell_time_label") +
              ": " +
              $d(new Date(planStartTime), "long")
            }}</span>
          </div>
        </v-col>
      </v-row>
      <v-row
        justify="center"
        no-gutters
        style="margin-top: 14px"
        v-if="whiteListShow == '1'"
      >
        <v-col class="text-center" align-self="center">
          <div class="flexCenter">
            <div class="whiteListButton">
              <span class="whiteListFont">{{ $t("drop.white_list") }}</span>
            </div>
          </div>
        </v-col>
      </v-row>
      <!-- <v-row
        justify="center"
        no-gutters
        style="margin-top: 14px"
        v-if="recStatus == '15'"
      >
        <v-col class="text-center" align-self="center">
          <div class="flexCenter">
            <!-- <div class="whiteListButton"> -->
              <!-- <span class="endStatusFont">{{ recStatusDesc }}</span> -->
            <!-- </div> -->
          <!-- </div>
        </v-col>
      </v-row>        -->
      <!-- </v-img> -->
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: "DropListItem",
  props: {
    id: {
      type: Number,
      default: 0,
    },
    activityNo: {
      type: String,
      default: "",
    },
    title: {
      type: String, // 标题
      default: "中文标题",
    },
    titleEn: {
      type: String,
      default: "Drop #001 Bearache 3D Limited Editions",
    },
    cover: {
      type: String, // 封面
      default: "",
    },
    bgImage: {
      type: String,
      default: "",
    },
    brandLogo: {
      type: String, // 品牌logo
      default: "",
    },
    brand: {
      type: String, // 品牌名
      default: "",
    },
    planStartTime: {
      type: String, // 发售时间
      default: "",
    },
    whiteListShow: {
      type: Number, //白名单发售
      default: 0,
    },
    recStatus: {
      type: String, //活动状态
      default: '0',
    },
    recStatusDesc: {
      type: String, //活动状态描述字
      default: '',
    },
  },
  data() {
    return {
      drop_title: "",
    };
  },
  watch: {
    "$i18n.locale"(n) {
      this.changeLang(n);
    },
  },
  mounted() {
    this.changeLang(this.$i18n.locale);
  },
  methods: {
    changeLang(lang) {
      if (lang === "en") {
        this.drop_title = this.titleEn;
      } else if (lang === "zh") {
        this.drop_title = this.title;
      }
    },
    dropDetail() {
      this.$router.push({ path: "/drop/" + this.activityNo });
      // window.open(route.href)
    },
  },
};
</script>

<style lang="scss" scoped>
.drop-base {
  height: 664px;
}
.image-box {
  background-size: cover;
  background-position: 50% 50%;
}
.goods-title {
  font-size: 56px;

  font-weight: bold;
  color: #ffffff;
  line-height: 75px;
  text-shadow: 0px 4px 12px rgba(0, 0, 0, 0.5);
}
.sell-time-bg {
  width: 380px;
  height: 58px;
  background: rgba(0, 0, 0, 0.4);
  border-radius: 38px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.sell-time-title {
  font-size: 22px;
  font-weight: 600;
  color: #ffffff;
  line-height: 75px;
}
</style>

<style>
.v-btn--is-elevated {
  box-shadow: none !important;
}
.v-sheet.v-card:not(.v-sheet--outlined) {
  box-shadow: none !important;
}
.flexCenter {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.whiteListButton {
  width: 92px;
  height: 30px;
  line-height: 30px;
  background: linear-gradient(149deg, #f9fe00 0%, #b4ff59 100%);
  border-radius: 8px;
}
.whiteListFont {
  font-size: 12px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #2a6811;
  line-height: 17px;
}
.endStatusFont {
  font-size: 60px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #000000;
  line-height: 65px;
}
</style>
