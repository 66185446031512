<template>
  <v-expand-transition>
    <v-app-bar
      :value="show"
      fixed
      :color="colors[color_idx]"
      flat
      height="84px"
      class="d-block ma-0 pa-0"
      style="z-index: 6; min-width: 960px"
    >
      <v-row class="fill-height" no-gutters justify="end">
        <!-- icon -->
        <v-col cols="2" align-self="center">
          <router-link to="/home">
            <v-img
              width="86"
              height="28"
              alt="logo"
              class="ml-15"
              :src="require(`@/assets/logo/logo@3x.png`)"
            />
          </router-link>
        </v-col>
        <v-spacer />
        <!-- 菜单 -->
        <v-col align-self="center" cols="5">
          <div class="myTabs">
            <v-tabs
              v-model="selectRouter"
              background-color="rgba(0,0,0,0)"
              class="d-flex menu-box align-baseline justify-end"
            >
              <v-tab
                v-for="item in routerList"
                :key="item.name"
                :to="item.path"
                class="menu-text px-5"
              >
                {{ $t("header." + item.name) }}
              </v-tab>
              <div class="menu-divier my-auto" />
              <v-tab
                to="/profile"
                style="padding-right: 10px; padding-left: 10px"
                @click="handleAvatar"
              >
                <img :src="avatar" class="myAvator" />
              </v-tab>
              <v-tab
                to="/nft"
                class="menu-text"
                style="padding-right: 10px; padding-left: 10px"
              >
                {{ $t("header.NFT") }}
              </v-tab>
              <v-tab
                class="menu-text"
                style="padding-right: 10px; padding-left: 10px"
                to="/wallet"
              >
                {{ $t("header.wallet") }}
              </v-tab>
            </v-tabs>
          </div>
        </v-col>
        <v-col align-self="center" cols="3" class="language-btns">
          <v-btn
            :class="[{ btn_selected: language === 'zh' }]"
            fab
            small
            class="menu-text mt-6"
            depressed
            color="rgba(0,0,0,0)"
            width="30px"
            height="30px"
            @click="handleSetLanguage('zh')"
          >
            {{ $t("header.CN") }}
          </v-btn>
          <v-btn
            :class="{ btn_selected: language === 'en' }"
            fab
            width="30px"
            height="30px"
            depressed
            class="menu-text mt-6"
            color="rgba(0,0,0,0)"
            @click="handleSetLanguage('en')"
          >
            {{ $t("header.EN") }}
          </v-btn>
        </v-col>
      </v-row>
      <login-dlg />
      <LoginOwner />
    </v-app-bar>
  </v-expand-transition>
</template>

<script>
import router from "@/router";
import LoginDlg from "./LoginDlg.vue";
import LoginOwner from "./LoginOwner/Index.vue";
import { mapGetters } from "vuex";
export default {
  name: "TopHeader",
  components: { LoginDlg, LoginOwner },
  data() {
    return {
      mode: 1,
      colors: ["transparent", "black"],
      show: true,
      routerList: router.getRoutes().filter((r) => r.name),
      changeFlag: 1,
      barFixed: true,
      color_idx: 0,
      sp_paths: ["/home", "/foundation"],
      selectRouter: null,
    };
  },
  computed: {
    language() {
      return this.$store.getters.language;
    },
    ...mapGetters(["avatar"]),
  },

  watch: {
    "$route.path"(val) {
      // console.log(val, ['/home'].indexOf(val))
      if (this.sp_paths.indexOf(val) === -1) {
        this.colors.splice(0, 1, "black");
      } else {
        this.colors.splice(0, 1, "transparent");
      }
    },
  },
  mounted() {
    window.addEventListener("scroll", this.scrollEvent);
    if (this.$store.getters.token) {
      this.$store.dispatch("GetUserInfo");
    }
  },
  created() {
    if (this.sp_paths.indexOf(this.$route.path) === -1) {
      this.colors.splice(0, 1, "black");
    } else {
      this.colors.splice(0, 1, "transparent");
    }
  },
  methods: {
    changeAppBarBackground(scroll) {
      if (!this.show) {
        return;
      }
      if (scroll > 80) {
        if (this.changeFlag === 1) {
          this.changeFlag = 2;
          this.show = false;
          const p = this;
          setTimeout(function () {
            p.show = true;
            p.color_idx = 1;
          }, 250);
        }
      } else {
        if (this.changeFlag === 2) {
          this.changeFlag = 1;
          this.color_idx = 0;
        }
      }
    },
    scrollEvent() {
      const s = document.documentElement.scrollTop || document.body.scrollTop;
      this.changeAppBarBackground(s);
    },

    handleSetLanguage(lang) {
      this.$i18n.locale = lang;
      this.$store.dispatch("setLanguage", lang);
    },
    // 点击头像
    handleAvatar() {
      if (this.$store.getters.token) {
        if (this.$route.path !== "/nft") {
          this.$router.push({ path: "/nft" });
        }
      } else {
        this.$store.dispatch("showLoginDlg", "/nft");
      }
    },
  },
};
</script>

<style scoped>
>>> .v-toolbar__content {
  padding: 0px !important;
}
>>> .v-tab {
  min-width: 0px;
  padding: 0;
}
.btn_selected {
  background-color: rgba(180, 255, 89, 1) !important;
}
.btn_selected >>> .v-btn__content {
  color: black !important;
}

.menu-text {
  height: 19px;
  font-size: 16px;
  font-weight: bold;
  color: #ffffff !important;
  line-height: 19px;
  margin-top: 30px;
}
.language-btns {
  height: 100%;
  max-width: 67px;
  margin-right: 60px;
  margin-left: 30px;
  justify-content: center;
}
.language-btns .menu-text {
  font-size: 12px;
}

.menu-box >>> .v-slide-group__wrapper {
  overflow-y: visible !important;
  height: 84px;
}

.menu-box >>> .v-slide-group__wrapper {
  overflow-y: visible !important;
  height: 84px;
}

.menu-box >>> .v-tabs-slider-wrapper {
  height: 5px !important;
  width: 44px;
  bottom: 10px;
}

.menu-box >>> .v-tabs-slider {
  width: 44px;
  height: 5px;
  margin-left: auto;
  margin-right: auto;
  background: #b4ff59;
  border-radius: 3px;
}

.menu-divier {
  width: 1px;
  height: 26px;
  opacity: 0.4;
  background: #ffffff;
  margin-right: 18px;
  margin-left: 13px;
}
.myAvator {
  height: 34px;
  width: 34px;
  border-radius: 50%;
}
.myTabs > div {
  height: 84px;
}
</style>
