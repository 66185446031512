const LoginOwnerEnum = {};

export const LoginOwnerLocalLang = {
  SubTitle: "loginOwner.loginOwnerSubTitle",
  /** 提示信息 */
  Prompt: "loginOwner.loginOwnerPrompt",
  /** 校验错误提示 */
  Error: "loginOwner.loginOwnerValdiateError",
  /** 提交按钮文字 */
  Button: "loginOwner.loginButton",
  /**
   * 创建成功
   */
  SetUpSuccess: "loginOwner.setUpSuccess",
  Logout: "login.logout_ok",
};

export const StoreActionsEnum = {
  Logout: "FedLogOut",
};
