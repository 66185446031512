import { MyMessageEnum } from "../enum/MyMessageEnum";
import Vue from "vue";
export default class MyMessage {
  /**
   * 弹出消息
   * @param {string} message 消息
   * @param {MyMessageEnum} MyMessageEnum
   */
  static showMessage(message, myMessageEnum = MyMessageEnum.Error) {
    Vue.notify({
      text: message,
      type: myMessageEnum,
    });
  }

  // /**
  //  * 确认框
  //  * @param {string} message 消息
  //  * @param {string} okEvent 成功回调事件
  //  * @param {MyMessageEnum} myMessageEnum
  //  * @param {string} title 标题
  //  * @param {string} confirmButtonText 确定显示文字
  //  * @param {string} cancelButtonText 取消显示文字
  //  */
  // static confirm(
  //   message,
  //   okEvent,
  //   cancelEvent = () => {},
  //   myMessageEnum = MyMessageEnum.Warning,
  //   title = "提示",
  //   confirmButtonText = "确定",
  //   cancelButtonText = "取消"
  // ) {
  //   MessageBox.confirm(message, title, {
  //     confirmButtonText,
  //     cancelButtonText,
  //     type: myMessageEnum,
  //   })
  //     .then(okEvent)
  //     .catch(cancelEvent);
  // }

  // static notify(message, title = "提示", duration = "0") {
  //   Notification({
  //     title,
  //     message,
  //     duration,
  //   });
  // }
}
