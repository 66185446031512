const brand = {
  state: {
    brandMap: {}
  },
  mutations: {
    ADD_BRAND: (state, brandid, brandInfo) => {
      state.brandMap[brandid] = brandInfo
    }
  },
  actions: {
    addBrand({ commit }, brandid, brandInfo) {
      commit('ADD_BRAND', brandid, brandInfo)
    }
  }
}

export default brand
