<template>
  <div style="margin-top:116px">
    <v-carousel
      delimiter-icon=""
      :hide-delimiter-background="true"
      :show-arrows="false"
      :show-arrows-on-hover="true"
      class="carousel"
      height="546"
      cycle
      interval="5000"
    >
      <v-carousel-item
        v-for="item in slideList"
        :key="item.id"
        active-class="carousel-item-active"
        :href="item.jump_url"
        target="_blank"
      >
        <v-img
          class="carousel-item"
          :src="item.image"
        >
          <span class="slide-title">{{ language==='en'?item.title_en:item.title }}</span>
          <div class="slide-title-box" />
        </v-img>
      </v-carousel-item>
    </v-carousel>
    <div class="d-flex aligin-center justify-center" style="margin-top: 42px;margin-bottom:21px">
      <v-img
        :src="require('@/assets/icon/fire@3x.png')"
        max-height="42px"
        max-width="32px"
        class="d-inline-block"
      />
      <span class="carousel-title"> {{ $t('drop.carousel_title') }} </span>
    </div>
    <drop-list-item v-for="item in dropList" :key="item.id" no-gutters v-bind="item" />
    <infinite-loading @infinite="moreDrop">
      <div slot="spinner">
        {{ $t('drop.loading') }}...
      </div>
      <div slot="no-more">
        {{ $t('drop.no_more_data') }}
      </div>
      <div slot="no-results">
        {{ $t('drop.no_data') }}
      </div>
    </infinite-loading>
  </div>
</template>

<script>
import InfiniteLoading from 'vue-infinite-loading'
import DropListItem from '../components/DropListItem.vue'
import { getDropList, bannerList } from '@/api/drop.js'
import { mapGetters } from 'vuex'
export default {
  components: { DropListItem, InfiniteLoading },
  data() {
    return {
      dropList: [],
      page: 1,
      slideList: [
        // {
        //   id: 1,
        //   title: '测试',
        //   title_en: 'TEST',
        //   image: 'http://kooola.oss-cn-beijing.aliyuncs.com/1d12c4481f1d4877a45e8eff5677c372.jpg',
        //   jump_url: '/drop/imageDhan20211105094444-F7E'
        // }, {
        //   id: 2,
        //   title: '外链测试',
        //   title_en: 'TEST OUTER',
        //   image: 'http://kooola.oss-cn-beijing.aliyuncs.com/c084b51aa773496dbc87c8137a7c1572.jpeg',
        //   jump_url: 'http://www.baidu.com'
        // }, {
        //   id: 3,
        //   title: '无跳转',
        //   title_en: 'no jump',
        //   image: 'http://kooola.oss-cn-beijing.aliyuncs.com/c084b51aa773496dbc87c8137a7c1572.jpeg',
        //   jump_url: null
        // }
      ]
    }
  },
  computed: {
    ...mapGetters([
      'language'
    ])
  },
  created() {
    bannerList().then(resp => {
      this.slideList = resp.items
    })
  },
  methods: {
    moreDrop($state) {
      getDropList(this.page, 5).then(response => {
        if (response.data.items.length) {
          this.page += 1
          this.dropList.push(...response.data.items)
          $state.loaded()
        } else {
          $state.complete()
        }
      })
    }
  }
}
</script>
<style lang="less" scoped>

.carousel /deep/ .v-window__container {
  height: 520px !important;
}
.carousel /deep/ .v-carousel__controls {
  position: relative;
  line-height: 6px;
  height: 6px;
  margin-top: 20px;
}
.carousel/deep/.v-btn--icon.v-size--small {
  width: 6px !important;
  height: 6px !important;
  background: #000000;
  opacity: 0.1;
}
.carousel/deep/.v-btn--active {
  opacity: 1 !important;
}

.carousel/deep/.v-btn--icon.v-size--small .v-icon, .v-btn--fab.v-size--small .v-icon{
  width: 6px;
  height: 6px;
}
.carousel/deep/.v-carousel__controls__item {
  margin: 0 4px;
}
.carousel-title {
  font-size: 32px;
  font-weight: bold;
  color: #000000;
  line-height: 39px;
  margin-left: 14px;
}
.carousel-item-active{
  padding: 0 100px;
}
.carousel-item {
  height: 520px;
  border-radius: 26px;
}
.slide-title-box {
  width: 100%;
  height: 100px;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%);
  border-radius: 0px 0px 26px 26px;
  opacity: 0.4;
  position:absolute;
  bottom: 0px;
}
.slide-title {
  font-size: 32px;
  font-weight: bold;
  color: rgba(255, 255, 255, 1);
  line-height: 39px;
  position: absolute;
  bottom: 26px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;
  max-height: 78px;
  overflow: hidden;
  white-space: nowrap;
}
</style>
