import store from "../store/index.js";

export class LoginControllerSingle {
  static loginController;
  /**
   * @returns {LoginController}
   */
  static CreateInstance() {
    if (this.loginController == undefined) {
      this.loginController = new LoginController();
    }
    return this.loginController;
  }
}

class LoginController {
  /**
   * @description 检查登陆账号是否有ownerId
   */
  checkOwnerId({ ownerid = undefined } = {}) {
    if (!ownerid) {
      this.toggleRegisterOwnerIdDialog({ visible: true });
    }
  }

  /**
   * @description 开关注册ownerid的弹窗
   */
  toggleRegisterOwnerIdDialog({ visible = false } = {}) {
    store.dispatch("showLoginOwnerDlg", visible);
  }
}
