const ICONS = {
  iconFace: {
    component: () => import('@/icons/icon_face.vue')
  },
  iconETH: {
    component: () => import('@/icons/icon_eth.vue')
  },
  iconDownMore: {
    component: () => import('@/icons/icon_down_more.vue')
  },
  iconUpLess: {
    component: () => import('@/icons/icon_up_less.vue')
  },
  iconBox: {
    component: () => import('@/icons/icon_box.vue')
  },
  iconRightArrow: {
    component: () => import('@/icons/icon_right_arrow.vue')
  },
  iconLeftArrow: {
    component: () => import('@/icons/icon_left_arrow.vue')
  },
  iconUSD: {
    component: () => import('@/icons/icon_usdc.vue')
  },
  iconBUSD: {
    component: () => import('@/icons/icon_busd.vue')
  },
  iconCNY: {
    component: () => import('@/icons/icon_cny.vue')
  },
  iconSOL: {
    component: () => import('@/icons/icon_solana.vue')
  },
  iconClothes: {
    component: () => import('@/icons/icon_clothes.vue')
  },
  iconEdit: {
    component: () => import('@/icons/icon_edit.vue')
  },
  iconRightArrow_min: {
    component: () => import('@/icons/icon_right_arrow.vue')
  }
}

export default ICONS
