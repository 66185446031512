import MyApi from "../../common/utils/MyApi";
export class LoginOwnerService {
  api;
  constructor() {
    this.api = new MyApi();
  }

  /**
   * @description 提交http请求注册ownerId
   * @param {{ownerId:string}}
   * ownerId 注册的ownerId
   * @returns
   */
  async registerOwnerId({ ownerId = undefined } = {}) {
    return await this.api.post("game/user/update", { ownerId });
  }
}
